<template>
	<modal class="NoxModalMailings" name="NoxModalMailings" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_open_loading" v-if="noxIsOpenLoading"><div class="nox_loading"></div></div>
			<div class="nox_modal_open" v-else>
				<div class="nox_modal_head">
					<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
					<div class="nox_modal_title">
						<span v-if="noxType == 'addMailing'">{{ 'Добавление ' + noxSType + ' рассылки' }}</span>
						<span v-else-if="noxType == 'addMBlacklist'">Добавление e-mail в черный список рассылок</span>
						<span v-else-if="noxType == 'editMailing'">{{ 'Редактирование ' + noxSType + ' рассылки' }}</span>
						<span v-else-if="noxType == 'reAddMailing'">{{ 'Добавление ' + noxSType + ' рассылки' }}</span>
						<span v-else-if="noxType == 'getMRecipients'">{{ 'Получатели ' + noxSType + ' рассылки' }}</span>
						<span v-else-if="noxType == 'startMailing'">{{ 'Запуск ' + noxSType + ' рассылки' }}</span>
						<span v-else-if="noxType == 'pauseMailing'">{{ 'Остановка ' + noxSType + ' рассылки' }}</span>
						<span v-else-if="noxType == 'continueMailing'">{{ 'Продолжение ' + noxSType + ' рассылки' }}</span>
						<span v-else-if="noxType == 'repeatMailing'">{{ 'Повторение ' + noxSType + ' рассылки' }}</span>
						<span v-else-if="noxType == 'deleteMailing'">{{ 'Удаление ' + noxSType + ' рассылки' }}</span>
						<span v-else-if="noxType == 'deleteMBlacklist'">Удаление e-mail из черного списка рассылок</span>
					</div>
				</div>
				<div class="nox_modal_body">
					<div class="nox_modal_info">
						<div class="nox_modal_info_mailings">
							<div v-if="['addMailing', 'editMailing', 'reAddMailing'].includes(noxType)">
								<div class="nox_modal_info_row" v-if="noxSType == 'email'">
									<div class="nox_modal_info_row_label">Тема рассылки (RU):</div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxSubjectRU" placeholder="укажите тему рассылки (ru)" autocomplete="off">
										<div v-html="noxAlertSubjectRU"></div>
									</div>
								</div>
								<div class="nox_modal_info_row" v-if="noxSType == 'email'">
									<div class="nox_modal_info_row_label">Тема рассылки (EN):</div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxSubjectEN" placeholder="укажите тему рассылки (en)" autocomplete="off">
										<div v-html="noxAlertSubjectEN"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">Текст рассылки (RU):</div>
									<div class="nox_modal_info_row_value">
										<textarea class="nox_textarea small" v-model="noxMessageRU" placeholder="укажите html текст рассылки (ru)"></textarea>
										<div v-html="noxAlertMessageRU"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">Текст рассылки (EN):</div>
									<div class="nox_modal_info_row_value">
										<textarea class="nox_textarea small" v-model="noxMessageEN" placeholder="укажите html текст рассылки (en)"></textarea>
										<div v-html="noxAlertMessageEN"></div>
									</div>
								</div>
								<div class="nox_modal_info_row last">
									<div class="nox_modal_info_row_label">Список получателей:</div>
									<div class="nox_modal_info_row_value">
										<v-select class="nox_select style-chooser" placeholder="выберите получателей рассылки" :options="noxTRecipientsOptions" @input="selectTRecipients"></v-select>
										<div v-html="noxAlertTRecipientsList"></div>
									</div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_mailings_trecipients">
										<div class="nox_modal_info_mailings_trecipients_data">
											<div class="nox_modal_info_mailings_trecipients_data_block" :class="{ hidden: noxIsTRecipientsHidden }">
												<div class="nox_modal_info_mailings_trecipient" :class="{ red: $parent.noxMailingsBlacklistEmails.includes(index) }" v-for="(value, index) in noxTRecipientsData" :key="index">
													<div>
														<span v-if="value.user_id"><b>NOX ID</b>: {{ value.user_id }},</span>
														<span>{{ (noxSType == 'telegram' ? (String(index).replace(/[^0-9]/gi, '')) : index) }}</span>
														<span v-if="value.username">({{ value.username }})</span>
													</div>
													<div @click="deleteTRecipients(index)"><font-awesome-icon :icon="['fas', 'times']" /></div>
												</div>
											</div>
											<div class="nox_modal_info_mailings_trecipients_data_button" v-if="noxIsTRecipientsHidden" @click="noxIsTRecipientsHidden = false">&#9660;&#160;показать всех получателей&#160;&#9660;</div>
											<textarea class="nox_textarea small" v-model.trim="noxTRecipientsTextarea" :placeholder="'получатели рассылки (пример: ' + (noxSType == 'email' ? 'example@gmail.com' : '9876543210') + ')'" @paste="insertTRecipients($event, 1)" @keyup.13="insertTRecipients($event, 2)"></textarea>
										</div>
										<div class="nox_modal_info_mailings_trecipients_count">
											<span>Количество:<b>{{ Object.keys(noxTRecipientsData).length }}</b></span>
											<span v-if="Object.keys(noxTRecipientsData).length" @click="deleteTRecipients('all')"><font-awesome-icon :icon="['fas', 'times']" /></span>
										</div>
									</div>
									<div v-html="noxAlertTRecipientsData"></div>
								</div>
								<div class="nox_modal_info_row">
									<div class="nox_modal_info_row_label">Размер рассылки:</div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxSize" :placeholder="'укажите количество ' + (noxSType == 'email' ? 'писем' : 'сообщений') + ' в одной пачке рассылки'" autocomplete="off">
										<div v-html="noxAlertSize"></div>
									</div>
								</div>
								<div class="nox_modal_info_row last">
									<div class="nox_modal_info_row_label">Интервал рассылки:</div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxInterval" placeholder="укажите интервал рассылки между пачками (в секундах)" autocomplete="off">
										<div v-html="noxAlertInterval"></div>
									</div>
								</div>
							</div>
							<div v-else-if="noxType == 'addMBlacklist'">
								<div class="nox_modal_info_row last">
									<div class="nox_modal_info_row_label">E-mail пользователя:</div>
									<div class="nox_modal_info_row_value">
										<input type="text" class="nox_input" v-model.trim="noxEmail" placeholder="укажите e-mail пользователя" autocomplete="off">
										<div v-html="noxAlertEmail"></div>
									</div>
								</div>
							</div>
							<div v-else-if="noxType == 'getMRecipients'">
								<div class="nox_modal_info_mailings_mrecipients">
									<table class="nox_table">
										<thead>
											<tr>
												<th v-html="$store.getters.getLanguageText('1.7.1', 22)"></th>
												<th v-html="$store.getters.getLanguageText('1.7.1', 42)"></th>
												<th v-html="$store.getters.getLanguageText('1.7.1', 11)"></th>
											</tr>
										</thead>
										<tbody>
											<tr v-for="(value, index) in noxMRecipientsData" :key="index">
												<td :data-label="$store.getters.getLanguageText('1.7.1', 22) + ':'">{{ (index + 1) }}</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 42) + ':'">{{ value.recipient }}</td>
												<td :data-label="$store.getters.getLanguageText('1.7.1', 11) + ':'"><span :class="[ (value.status == 1 ? 'green' : '') ]" v-html="$store.getters.getLanguageText('1.7.2', [5,20][value.status])"></span></td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
							<div v-else-if="noxType == 'startMailing'">Вы действительно хотите запустить рассылку?</div>
							<div v-else-if="noxType == 'pauseMailing'">Вы действительно хотите остановить рассылку?</div>
							<div v-else-if="noxType == 'continueMailing'">Вы действительно хотите продолжить рассылку?</div>
							<div v-else-if="noxType == 'repeatMailing'">Вы действительно хотите повторить рассылку?</div>
							<div v-else-if="noxType == 'deleteMailing'">Вы действительно хотите удалить рассылку?</div>
							<div v-else-if="noxType == 'deleteMBlacklist'">Вы действительно хотите удалить e-mail из черного списка рассылок?</div>
						</div>
					</div>
					<div class="nox_modal_alert" v-html="noxAlert"></div>
					<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
					<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
						<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
						<button type="button" class="nox_button common green" @click="axios" v-if="noxType == 'addMailing'" v-html="$store.getters.getLanguageText('1.1', 4)"></button>
						<button type="button" class="nox_button common green" @click="axios" v-else-if="noxType == 'addMBlacklist'" v-html="$store.getters.getLanguageText('1.1', 5)"></button>
						<button type="button" class="nox_button common green" @click="axios" v-else-if="noxType == 'editMailing'" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
						<button type="button" class="nox_button common" @click="axios" v-else-if="noxType == 'getMRecipients' && noxMRecipientsDataLimitOffset" v-html="$store.getters.getLanguageText('1.1', 20)"></button>
						<button type="button" class="nox_button common green" @click="axios" v-else-if="noxType == 'startMailing'" v-html="$store.getters.getLanguageText('1.1', 132)"></button>
						<button type="button" class="nox_button common green" @click="axios" v-else-if="noxType == 'pauseMailing'" v-html="$store.getters.getLanguageText('1.1', 133)"></button>
						<button type="button" class="nox_button common green" @click="axios" v-else-if="noxType == 'continueMailing'" v-html="$store.getters.getLanguageText('1.1', 64)"></button>
						<button type="button" class="nox_button common green" @click="close();$modal.show('NoxModalMailings', { type: 'reAddMailing', stype: noxSType, index: noxIndex, id: noxId })" v-else-if="noxType == 'repeatMailing'" v-html="$store.getters.getLanguageText('1.1', 134)"></button>
						<button type="button" class="nox_button common red" @click="axios" v-else-if="noxType == 'deleteMailing'" v-html="$store.getters.getLanguageText('1.1', 11)"></button>
						<button type="button" class="nox_button common red" @click="axios" v-else-if="noxType == 'deleteMBlacklist'" v-html="$store.getters.getLanguageText('1.1', 11)"></button>
					</div>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxAlert: '',
			noxAlertEmail: '',
			noxAlertSubjectRU: '',
			noxAlertSubjectEN: '',
			noxAlertMessageRU: '',
			noxAlertMessageEN: '',
			noxAlertTRecipientsList: '',
			noxAlertTRecipientsData: '',
			noxAlertSize: '',
			noxAlertInterval: '',
			noxId: 0,
			noxType: '',
			noxIndex: 0,
			noxSType: '',
			noxEmail: '',
			noxSubjectRU: '',
			noxSubjectEN: '',
			noxMessageRU: '',
			noxMessageEN: '',
			noxMRecipientsData: [],
			noxMRecipientsDataLimitOffset: 0,
			noxMRecipientsDataLimitCount: 50,
			noxTRecipients: [],
			noxTRecipientsData: {},
			noxTRecipientsTextarea: '',
			noxTRecipientsOptions: [
				{ code: '1', label: 'Все пользователи' },
				{ code: '2', label: 'Только забаненные' },
				{ code: '3', label: 'Только не забаненные' },
				{ code: '4', label: 'Только квалифицированные' },
				{ code: '5', label: 'Только неквалифицированные' },
				{ code: '6', label: 'Только активные' },
				{ code: '7', label: 'Только неактивные' },
				{ code: '8', label: 'Только аутсайдеры' },
				{ code: '9', label: 'Только не аутсайдеры' },
				{ code: '10', label: 'Только просроченные' },
				{ code: '11', label: 'Только не просроченные' },
				{ code: '12', label: 'Только с пакетом' },
				{ code: '13', label: 'Только с пакетом Demo' },
				{ code: '14', label: 'Только с пакетом XXXS' },
				{ code: '15', label: 'Только с пакетом XXS' },
				{ code: '16', label: 'Только с пакетом XS' },
				{ code: '17', label: 'Только с пакетом S' },
				{ code: '18', label: 'Только с пакетом M' },
				{ code: '19', label: 'Только с пакетом L' },
				{ code: '20', label: 'Только с пакетом XL' },
				{ code: '21', label: 'Только с пакетом XXL' },
				{ code: '22', label: 'Только с пакетом XXXL' },
				{ code: '23', label: 'Только с ботами' },
				{ code: '24', label: 'Только без ботов' },
				{ code: '25', label: 'Только с серверами NOX Box' },
				{ code: '26', label: 'Только без серверов NOX Box' }
			],
			noxSize: '',
			noxInterval: '',
			noxIsTRecipientsHidden: false,
			noxIsOpenLoading: false,
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.resetAlerts();
				this.noxId = event.params.id;
				this.noxType = event.params.type;
				this.noxIndex = event.params.index;
				this.noxSType = event.params.stype;
				this.noxEmail = event.params.email;
				this.noxSubjectRU = '';
				this.noxSubjectEN = '';
				this.noxMessageRU = '';
				this.noxMessageEN = '';
				this.noxMRecipientsData = [];
				this.noxMRecipientsDataLimitOffset = 0;
				this.noxMRecipientsDataLimitCount = 50;
				this.noxTRecipients = [];
				this.noxTRecipientsData = {};
				this.noxTRecipientsTextarea = '';
				this.noxSize = '';
				this.noxInterval = '';
				this.noxIsTRecipientsHidden = false;
				this.noxIsOpenLoading = false;
				this.noxIsLoading = false;
				this.noxIsError = false;

				if (['editMailing', 'reAddMailing'].includes(this.noxType)) {

					this.noxSubjectRU = this.$parent.noxMailingsData[this.noxIndex].subject_ru;
					this.noxSubjectEN = this.$parent.noxMailingsData[this.noxIndex].subject_en;
					this.noxMessageRU = this.$parent.noxMailingsData[this.noxIndex].message_ru;
					this.noxMessageEN = this.$parent.noxMailingsData[this.noxIndex].message_en;
					this.noxInterval = this.$parent.noxMailingsData[this.noxIndex].interval;
					this.noxSize = this.$parent.noxMailingsData[this.noxIndex].size;
					this.axios('getMRecipients');
				}
				else if (this.noxType == 'getMRecipients') { this.axios(); }
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertEmail = '';
				this.noxAlertSubjectRU = '';
				this.noxAlertSubjectEN = '';
				this.noxAlertMessageRU = '';
				this.noxAlertMessageEN = '';
				this.noxAlertTRecipientsList = '';
				this.noxAlertTRecipientsData = '';
				this.noxAlertSize = '';
				this.noxAlertInterval = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = 'Необходимо указать id рассылки.'; }
				else if (i == 2) { this.noxTemp = 'Необходимо указать верный id рассылки.'; }
				else if (i == 3) { this.noxTemp = 'Необходимо указать тип рассылки.'; }
				else if (i == 4) { this.noxTemp = 'Необходимо указать верный тип рассылки.'; }
				else if (i == 5) { this.noxTemp = 'Необходимо указать тему рассылки (ru).'; }
				else if (i == 6) { this.noxTemp = 'Необходимо указать верную тему рассылки (ru).'; }
				else if (i == 7) { this.noxTemp = 'Необходимо указать тему рассылки (en).'; }
				else if (i == 8) { this.noxTemp = 'Необходимо указать верную тему рассылки (en).'; }
				else if (i == 9) { this.noxTemp = 'Необходимо указать HTML текст рассылки (ru).'; }
				else if (i == 10) { this.noxTemp = 'Необходимо указать верный HTML текст рассылки (ru).'; }
				else if (i == 11) { this.noxTemp = 'Необходимо указать HTML текст рассылки (en).'; }
				else if (i == 12) { this.noxTemp = 'Необходимо указать верный HTML текст рассылки (en).'; }
				else if (i == 13) { this.noxTemp = 'Необходимо выбрать получателей рассылки.'; }
				else if (i == 14) { this.noxTemp = 'Необходимо указать список получателей рассылки.'; }
				else if (i == 15) { this.noxTemp = 'Необходимо указать верный список получателей рассылки.'; }
				else if (i == 16) { this.noxTemp = 'Необходимо указать id списка получателей рассылки.'; }
				else if (i == 17) { this.noxTemp = 'Необходимо указать верный id списка получателей рассылки.'; }
				else if (i == 18) { this.noxTemp = 'Необходимо указать смещение записей списка получателей рассылки.'; }
				else if (i == 19) { this.noxTemp = 'Необходимо указать верное смещение записей списка получателей рассылки.'; }
				else if (i == 20) { this.noxTemp = 'Необходимо указать лимит записей списка получателей рассылки.'; }
				else if (i == 21) { this.noxTemp = 'Необходимо указать верный лимит записей списка получателей рассылки.'; }
				else if (i == 22) { this.noxTemp = 'Необходимо указать размер рассылки.'; }
				else if (i == 23) { this.noxTemp = 'Необходимо указать верный размер рассылки.'; }
				else if (i == 24) { this.noxTemp = 'Необходимо указать интервал рассылки.'; }
				else if (i == 25) { this.noxTemp = 'Необходимо указать верный интервал рассылки.'; }
				else if (i == 26) { this.noxTemp = 'Необходимо указать e-mail пользователя.'; }
				else if (i == 27) { this.noxTemp = 'Необходимо указать верный e-mail пользователя.'; }
				else if (i == 28) { this.noxTemp = 'Id рассылки не найден в системе.'; }
				else if (i == 29) { this.noxTemp = 'E-mail пользователя не найден в системе.'; }
				else if (i == 30) { this.noxTemp = 'Такой e-mail пользователя уже есть в системе.'; }
				else              { this.noxTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function(type, var1) {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (['addMailing', 'editMailing', 'reAddMailing'].includes(_this.noxType)) {
						if (type == 'getMRecipients') {
							_this.noxIsOpenLoading = true;
							config.url = '/v2/admin/account/mailings/mrecipients';
							config.params = { id: _this.noxId, limitOffset: 0, limitCount: 1000000 };
						}
						else if (type == 'getTRecipients') {
							config.url = '/v2/admin/account/mailings/trecipients';
							config.params = { trid: var1, type: (_this.noxSType == 'email' ? 1 : 2) };
						}
						else if (['email', 'telegram'].includes(_this.noxSType)) {
							if (_this.noxSType == 'email') {
								if (!_this.noxSubjectRU) {
									_this.noxAlertSubjectRU = _this.getError(5);
								}
								else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxSubjectRU)) {
									_this.noxAlertSubjectRU = _this.getError(6);
								}
								if (!_this.noxSubjectEN) {
									_this.noxAlertSubjectEN = _this.getError(7);
								}
								else if (!_this.$store.state.noxRegexENRULettersAndNumbersAndMarks.test(_this.noxSubjectEN)) {
									_this.noxAlertSubjectEN = _this.getError(8);
								}
							}
							if (!_this.noxMessageRU) {
								_this.noxAlertMessageRU = _this.getError(9);
							}
							if (!_this.noxMessageEN) {
								_this.noxAlertMessageEN = _this.getError(11);
							}
							_this.noxTRecipients = [];
							if (Object.keys(_this.noxTRecipientsData).length) {
								for (var i in _this.noxTRecipientsData) {
									if (!_this.$parent.noxMailingsBlacklistEmails.includes(i)) {
										_this.noxTRecipients.push(_this.noxSType == 'telegram' ? String(i).replace(/[^0-9]/gi, '') : i);
									}
								}
							}
							else {
								_this.noxAlertTRecipientsList = _this.getError(13);
							}
							if (!_this.noxSize) {
								_this.noxAlertSize = _this.getError(22);
							}
							else if (!_this.$store.state.noxRegex_d.test(_this.noxSize) || _this.noxSize < 1) {
								_this.noxAlertSize = _this.getError(23);
							}
							if (!_this.noxInterval) {
								_this.noxAlertInterval = _this.getError(24);
							}
							else if (!_this.$store.state.noxRegex_d.test(_this.noxInterval) || _this.noxInterval < 1) {
								_this.noxAlertInterval = _this.getError(25);
							}
							if (!_this.noxIsError) {
								config.url = '/v2/admin/account/mailings/' + _this.noxSType;
								config.data = { id: _this.noxId, subject_ru: _this.noxSubjectRU, subject_en: _this.noxSubjectEN, message_ru: _this.noxMessageRU, message_en: _this.noxMessageEN, recipients: JSON.stringify(_this.noxTRecipients), size: _this.noxSize, interval: _this.noxInterval };
								config.method = _this.noxType == 'addMailing' ? 'post' : 'patch';
							}
						}
					}
					else if (_this.noxType == 'addMBlacklist') {
						if (!_this.noxEmail) {
							_this.noxAlertEmail = _this.getError(26);
						}
						else if (!_this.$store.state.noxRegexEmail.test(_this.noxEmail)) {
							_this.noxAlertEmail = _this.getError(27);
						}
						if (!_this.noxIsError) {
							config.url = '/v2/admin/account/mailings/blacklist';
							config.data = { email: _this.noxEmail };
							config.method = 'post';
						}
					}
					else if (_this.noxType == 'getMRecipients') {
						_this.noxIsOpenLoading = !_this.noxMRecipientsDataLimitOffset ? true : false;
						config.url = '/v2/admin/account/mailings/mrecipients';
						config.params = { id: _this.noxId, limitOffset: _this.noxMRecipientsDataLimitOffset, limitCount: _this.noxMRecipientsDataLimitCount };
					}
					else if (_this.noxType == 'startMailing') {
						config.url = '/v2/admin/account/mailings/start';
						config.data = { id: _this.noxId };
						config.method = 'patch';
					}
					else if (_this.noxType == 'pauseMailing') {
						config.url = '/v2/admin/account/mailings/pause';
						config.data = { id: _this.noxId };
						config.method = 'patch';
					}
					else if (_this.noxType == 'continueMailing') {
						config.url = '/v2/admin/account/mailings/continue';
						config.data = { id: _this.noxId };
						config.method = 'patch';
					}
					else if (_this.noxType == 'deleteMailing') {
						config.url = '/v2/admin/account/mailings';
						config.data = { id: _this.noxId };
						config.method = 'delete';
					}
					else if (_this.noxType == 'deleteMBlacklist') {
						config.url = '/v2/admin/account/mailings/blacklist';
						config.data = { email: _this.noxEmail };
						config.method = 'delete';
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (['addMailing', 'editMailing', 'reAddMailing'].includes(_this.noxType)) {
									if (type == 'getMRecipients') {
										_this.noxTRecipientsData = {};
										if (data.data.mrecipients_data !== undefined) {
											if (data.data.mrecipients_data.data.length) {
												for (var i in data.data.mrecipients_data.data) {
													_this.noxTRecipientsData[data.data.mrecipients_data.data[i].recipient] = {};
												}
											}
										}
										_this.noxType = (_this.noxType == 'reAddMailing' ? 'addMailing' : _this.noxType);
										_this.noxIsTRecipientsHidden = (Object.keys(_this.noxTRecipientsData).length > 10 ? true : false);
									}
									else if (type == 'getTRecipients') {
										if (Object.keys(data.data).length) {
											_this.noxTRecipientsData = Object.assign(_this.noxTRecipientsData, data.data);
										}
										_this.noxIsTRecipientsHidden = (Object.keys(_this.noxTRecipientsData).length > 10 ? true : false);
									}
									else if (_this.noxType == 'addMailing') {
										_this.$parent.noxMailingsData.unshift(data.data);
										_this.close();
									}
									else if (_this.noxType == 'editMailing') {
										_this.$parent.noxMailingsData.splice(_this.noxIndex, 1, data.data);
										_this.close();
									}
								}
								else if (_this.noxType == 'addMBlacklist') {
									_this.$parent.noxMailingsBlacklist.unshift(data.data);
									_this.close();
								}
								else if (_this.noxType == 'getMRecipients') {
									if (data.data.mrecipients_data !== undefined) {
										if (data.data.mrecipients_data.data.length) {
											for (var j in data.data.mrecipients_data.data) {
												_this.noxMRecipientsData.push(data.data.mrecipients_data.data[j]);
											}
										}
										_this.noxMRecipientsDataLimitOffset = data.data.mrecipients_data.count > _this.noxMRecipientsDataLimitOffset + _this.noxMRecipientsDataLimitCount ? _this.noxMRecipientsDataLimitOffset + _this.noxMRecipientsDataLimitCount : 0;
									}
								}
								else if (['startMailing', 'pauseMailing', 'continueMailing'].includes(_this.noxType)) {
									_this.$parent.noxMailingsData.splice(_this.noxIndex, 1, data.data);
									_this.close();
								}
								else if (_this.noxType == 'deleteMailing') {
									_this.$parent.noxMailingsData.splice(_this.noxIndex, 1);
									_this.close();
								}
								else if (_this.noxType == 'deleteMBlacklist') {
									_this.$parent.noxMailingsBlacklist.splice(_this.noxIndex, 1);
									_this.close();
								}
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (['addMailing', 'editMailing', 'reAddMailing', 'getMRecipients', 'startMailing', 'pauseMailing', 'continueMailing', 'deleteMailing'].includes(_this.noxType)) {
										if      (data.response.data.error == 'ID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'ID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
										else if (data.response.data.error == 'TYPE_IS_EMPTY') { _this.noxAlertTRecipientsList = _this.getError(3); }
										else if (data.response.data.error == 'TYPE_NOT_VALID') { _this.noxAlertTRecipientsList = _this.getError(4); }
										else if (data.response.data.error == 'SUBJECT_RU_IS_EMPTY') { _this.noxAlertSubjectRU = _this.getError(5); }
										else if (data.response.data.error == 'SUBJECT_RU_NOT_VALID') { _this.noxAlertSubjectRU = _this.getError(6); }
										else if (data.response.data.error == 'SUBJECT_EN_IS_EMPTY') { _this.noxAlertSubjectEN = _this.getError(7); }
										else if (data.response.data.error == 'SUBJECT_EN_NOT_VALID') { _this.noxAlertSubjectEN = _this.getError(8); }
										else if (data.response.data.error == 'MESSAGE_RU_IS_EMPTY') { _this.noxAlertMessageRU = _this.getError(9); }
										else if (data.response.data.error == 'MESSAGE_RU_NOT_VALID') { _this.noxAlertMessageRU = _this.getError(10); }
										else if (data.response.data.error == 'MESSAGE_EN_IS_EMPTY') { _this.noxAlertMessageEN = _this.getError(11); }
										else if (data.response.data.error == 'MESSAGE_EN_NOT_VALID') { _this.noxAlertMessageEN = _this.getError(12); }
										else if (data.response.data.error == 'RECIPIENTS_IS_EMPTY') { _this.noxAlertTRecipientsList = _this.getError(13); }
										else if (data.response.data.error == 'RECIPIENTS_NOT_VALID') { _this.noxAlertTRecipientsData = _this.getError(15); }
										else if (data.response.data.error == 'TRID_IS_EMPTY') { _this.noxAlertTRecipientsList = _this.getError(16); }
										else if (data.response.data.error == 'TRID_NOT_VALID') { _this.noxAlertTRecipientsList = _this.getError(17); }
										else if (data.response.data.error == 'LIMIT_OFFSET_IS_EMPTY') { _this.noxAlert = _this.getError(18); }
										else if (data.response.data.error == 'LIMIT_OFFSET_NOT_VALID') { _this.noxAlert = _this.getError(19); }
										else if (data.response.data.error == 'LIMIT_COUNT_IS_EMPTY') { _this.noxAlert = _this.getError(20); }
										else if (data.response.data.error == 'LIMIT_COUNT_NOT_VALID') { _this.noxAlert = _this.getError(21); }
										else if (data.response.data.error == 'SIZE_IS_EMPTY') { _this.noxAlertSize = _this.getError(22); }
										else if (data.response.data.error == 'SIZE_NOT_VALID') { _this.noxAlertSize = _this.getError(23); }
										else if (data.response.data.error == 'INTERVAL_IS_EMPTY') { _this.noxAlertInterval = _this.getError(24); }
										else if (data.response.data.error == 'INTERVAL_NOT_VALID') { _this.noxAlertInterval = _this.getError(25); }
									}
									else if (_this.noxType == 'addMBlacklist') {
										if      (data.response.data.error == 'EMAIL_IS_EMPTY') { _this.noxAlertEmail = _this.getError(26); }
										else if (data.response.data.error == 'EMAIL_NOT_VALID') { _this.noxAlertEmail = _this.getError(27); }
									}
									else if (_this.noxType == 'deleteMBlacklist') {
										if      (data.response.data.error == 'EMAIL_IS_EMPTY') { _this.noxAlert = _this.getError(26); }
										else if (data.response.data.error == 'EMAIL_NOT_VALID') { _this.noxAlert = _this.getError(27); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (['addMailing', 'editMailing', 'reAddMailing', 'getMRecipients', 'startMailing', 'pauseMailing', 'continueMailing', 'deleteMailing'].includes(_this.noxType)) {
										if (data.response.data.error == 'ID_NOT_FOUND') { _this.noxAlert = _this.getError(28); }
									}
									else if (_this.noxType == 'deleteMBlacklist') {
										if (data.response.data.error == 'EMAIL_NOT_FOUND') { _this.noxAlert = _this.getError(29); }
									}
								}
								else if (data.response.status == 409) {
									if (_this.noxType == 'addMBlacklist') {
										if (data.response.data.error == 'EMAIL_ALREADY_ADDED') { _this.noxAlertEmail = _this.getError(30); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsOpenLoading = false;
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			selectTRecipients: function(event) {
				this.axios('getTRecipients', Number(event.code));
			},
			insertTRecipients: function(event, type) {
				var recipients = (type == 1 ? event.clipboardData.getData('text') : this.noxTRecipientsTextarea).split(/,|;|\s+|\n/).filter(recipient => (recipient && this.$store.state[(this.noxSType == 'email' ? 'noxRegexEmail' : 'noxRegexTelegramId')].test(recipient)));
				if (recipients.length) {
					var data = {}; for (var i in recipients) { data[recipients[i]] = {}; }
					this.noxTRecipientsData = Object.assign(this.noxTRecipientsData, data);
				}
				event.target.blur(); var _this = this; setTimeout(function() { _this.noxTRecipientsTextarea = ''; }, 0);
			},
			deleteTRecipients: function(index) {
				if (index == 'all') { this.noxTRecipientsData = {}; } else { this.$delete(this.noxTRecipientsData, index); }
				this.noxIsTRecipientsHidden = (Object.keys(this.noxTRecipientsData).length > 10 && this.noxIsTRecipientsHidden ? true : false);
			},
			close: function() {
				this.$modal.hide('NoxModalMailings');
			}
		}
	}
</script>
